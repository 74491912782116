import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BatchEmailBuilderPage.css';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import { AuthContext } from "../../contexts/AuthContext";
import { createBatchJob, startBatchJob } from "../../services/batchEmails";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useScrollToError from "../../hooks/useScrollToError";
import QuanteraLoader from "../../components/LoadingAnimations/QuanteraLoader";
import SelectDeal from "../../components/SelectDeal/SelectDeal";
import Combobox from "../../components/FormFields/Combobox";
import { getEmailTemplates } from "../../services/emails";
import { getReportList } from "../../services/reports";
import { getDeal } from "../../services/companies";

const BatchEmailBuilderPage = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [requiredContextInputs, setRequiredContextInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [jobID, setJobID] = useState('');
  const buttonsRef = useRef(null);
  const [emails, setEmails] = useState([
    { deal: null, report: null }
  ]);
  const location = useLocation();
  

  useScrollToError(buttonsRef, errorMessage);

  useEffect(() => {
    // Fetch email templates when component mounts
    const fetchTemplates = async () => {
      try {
        const response = await getEmailTemplates(false, true);
        const templates = response.templates.map((template) => ({
          label: template.name,
          value: template.id,
          context_inputs: template.context_inputs
        }));
        setTemplateOptions(templates);
        return templates;
      } catch (error) {
        console.error(error);
        setErrorMessage("Failed to fetch email templates");
        return [];
      }
    };

    // Load preloaded deals (if any) when component mounts
    const loadPreloadedDeals = async (templates) => {
      const preloadedDealIds = location.state?.preloadedDealIds || [];
      if (preloadedDealIds.length > 0) {
        // Fetch deals for each preloaded deal ID
        const deals = await Promise.all(preloadedDealIds.map(id => getDeal(id)));
        setEmails(deals.map(deal => ({ deal: deal, report: null })));

        // Select the first template as default if preloaded deal IDs are present
        if (location.state?.preloadedDealIds?.length > 0) {
          setSelectedTemplateId(templates[0]?.value);
        }

        // Remove preloaded deal IDs from location state
        const newState = { ...location.state };
        delete newState.preloadedDealIds;
        window.history.replaceState(newState, document.title);
      }
    };

    if (isAuthenticated) {
      fetchTemplates().then((templates) => loadPreloadedDeals(templates));
    }
  }, [isAuthenticated]);

  // If a new selected template requires reports as input, fetch the reports for each company selected
  useEffect(() => {
    emails.forEach((email, index) => getReportsForDeal(index, email.deal));
  }, [requiredContextInputs?.report]);

  // Update required inputs when template changes
  useEffect(() => {
    if (selectedTemplateId) {
      const template = templateOptions.find(t => t.value === selectedTemplateId);
      setRequiredContextInputs(template ? template.context_inputs : {});
    }
  }, [selectedTemplateId, templateOptions]);

  // Fetch reports for a given deal
  const getReportsForDeal = async (index, deal, updatedEmails = []) => {
    // Fetch reports for the selected deal if needed
    if (requiredContextInputs.report) {
      try {
        const response = await getReportList({
          page: 1,
          per_page: 10,
          deal_id: deal.id
        });
        const reports = response.reports.map((report) => ({
          label: `${report.title} - ${report.date}`,
          value: report.id,
        }));
        if (updatedEmails.length === 0) {
          updatedEmails = [...emails];
        }
        updatedEmails[index].reportOptions = reports;
        setEmails([...updatedEmails]);
      } catch (error) {
        console.error(error);
      }
    }
  }

  // Handle company selection for a given email row input
  const handleDealSelect = async (index, deal) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = {
      ...updatedEmails[index],
      deal: deal,
      report: null // Reset report when deal changes
    };
    setEmails(updatedEmails);

    // Fetch reports for the selected deal if needed
    // We pass in the updated emails array to avoid async issues with setEmails not updating immediately
    getReportsForDeal(index, deal, updatedEmails);
  };

  // Handle report selection for a given email row input
  const handleReportSelect = (index, reportId) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = {
      ...updatedEmails[index],
      report: reportId
    };
    setEmails(updatedEmails);
  };

  // Add a new email input field
  const addEmailInput = () => {
    setEmails([...emails, { deal: null, report: null }]);
  };

  // Remove an email input field
  const removeEmailInput = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  // Build the batch job
  const handleBuildEmails = async () => {
    try {
      if (!selectedTemplateId) {
        setErrorMessage("Please select an email template");
        return;
      }

      const emailsData = emails.map(email => ({
        deal_id: email.deal?.id,
        company_id: email.deal?.company_id,
        report_id: email.report
      }));

      setJobID('');
      setIsLoadingJob(true);
      setProgressMessage('👷 Parsing template and building batch job...');
      setErrorMessage('');

      const jobResponse = await createBatchJob({
        email_template_id: selectedTemplateId,
        emails: emailsData
      });

      setProgressMessage('📅 Scheduling batch job...')
      await startBatchJob({
        job_id: jobResponse.job_id
      });

      setJobID(jobResponse.job_id);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoadingJob(false);
    }
  };

  return (
    <RequireLogin redirect>
      <PageWrapper title="Batch Email Builder">

        <Card className="mb-3">
          <Card.Header>Instructions</Card.Header>
          <Card.Body>
            <ol className="mb-0 fw-light lh-16">
              <li>Select the template you wish to use for your emails.</li>
              <li>For each email you want to generate, select the company{requiredContextInputs.report && " and report"}.</li>
              <li>Click <kbd>Build Emails</kbd> to initiate the batch job.</li>
              <li>A link will be provided to a page where you can check the status of your job.</li>
            </ol>
          </Card.Body>
        </Card>

        <div>
          <Combobox
            placeholder="Select an email template..."
            options={templateOptions}
            selectedItem={selectedTemplateId}
            setSelectedItem={setSelectedTemplateId}
            searchPlaceholder="Filter templates..."
            className="mb-3"
            dropdownClassName="w-100"
            width="100%"
          />

          {selectedTemplateId && (
            <>
              {emails.map((email, index) => (
                <div key={index} className="email-input-row d-flex align-items-start mb-3">
                  <div className="email-input-fields d-flex flex-grow-1">
                    <div className="flex-grow-1">
                      <SelectDeal
                        onSelect={(deal) => handleDealSelect(index, deal)}
                        selectedDeal={email.deal}
                      />
                    </div>

                    {requiredContextInputs.report && email.deal && (
                      <div className="flex-grow-1">
                        <Combobox
                          placeholder="Select a report..."
                          options={email.reportOptions || []}
                          selectedItem={email.report}
                          setSelectedItem={(reportId) => handleReportSelect(index, reportId)}
                          searchPlaceholder="Filter reports..."
                          dropdownClassName="w-100"
                          width="100%"
                        />
                      </div>
                    )}
                    
                    {emails.length > 1 && (
                      <div className="d-flex align-items-center">
                        <Button
                          variant="outline-dark"
                          className="remove-email-btn"
                          onClick={() => removeEmailInput(index)}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    )}

                  </div>
                </div>
              ))}

              <div ref={buttonsRef}>
                {!jobID ? (
                  <>
                    <Button
                      variant="outline-dark"
                      className="mb-4 me-2"
                      onClick={addEmailInput}
                    >
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      Add Email
                    </Button>

                    <Button variant="dark" className="mb-4" onClick={handleBuildEmails}>
                      Build Emails
                    </Button>
                  </>
                ) : (
                  <>
                    <h4>🚀 Batch job launched!</h4>
                    <Link to={`/emails/job/${jobID}`}>
                      <Button variant="secondary" className="me-2 mt-3">
                        View Job Status
                      </Button>
                    </Link>
                  </>
                )}
              </div>

              {isLoadingJob && (
                <div className="text-center email-builder-loading-box">
                  <QuanteraLoader sizePixels={70} />
                  {progressMessage}
                </div>
              )}

              {errorMessage && (
                <Alert variant="danger" className="mb-3">
                  {errorMessage}
                </Alert>
              )}
            </>
          )}
        </div>
      </PageWrapper>
    </RequireLogin>
  );
};

export default BatchEmailBuilderPage;