import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import './EmailTemplateContext.css';

const EmailTemplateContext = (
  {
    contextInputs,
    setContextInputs,
    contextWebSearches,
    setContextWebSearches,
  }) => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputIndex, setInputIndex] = useState(-1);

  const handleCheckboxChange = (event) => {
    if (event.target.name === "websearch" && !event.target.checked) {
      setContextWebSearches([]);
    }
    setContextInputs({
      ...contextInputs,
      [event.target.name]: event.target.checked
    });
  };

  const handleAddQuery = () => {
    if (inputValue.trim()) {
      let newContextWebSearches = [...contextWebSearches];

      if (inputIndex === -1) {
        // Add to the end if index is -1
        newContextWebSearches.push(inputValue);
      } else {
        // Replace the element at the specified index if index is not -1
        newContextWebSearches[inputIndex] = inputValue;
      }

      setContextWebSearches(newContextWebSearches);
      setInputValue('');
      setShowModal(false);
    }
  };

  const handleDeleteQuery = (index) => {
    setContextWebSearches(contextWebSearches.filter((_, i) => i !== index));
  };

  const handleEditQuery = (index, query) => {
    setInputIndex(index);
    setInputValue(query);
    setShowModal(true);
  }

  const handleShowModal = () => {
    setInputIndex(-1);
    setInputValue("");
    setShowModal(true);
  }

  return (
    <div className="email-template-context px-3 py-2">
      <p>The Context Builder feeds a range of data sources to the AI when emails are generated to enrich the information available to it. Select from the data sources below.</p>
      <Form.Group>

        <Form.Check
          className="mb-1"
          type="checkbox"
          id="reportCheck"
          label="Company Report"
          name="report"
          checked={contextInputs.report}
          onChange={handleCheckboxChange}
        />
        <div className="text-secondary small mb-3 ms-4">Allows you to select an input report during email generation</div>

        <Form.Check
          className="mb-1"
          type="checkbox"
          id="newsCheck"
          label="Latest News"
          name="news"
          checked={contextInputs.news}
          onChange={handleCheckboxChange}
        />
        <div className="text-secondary small mb-3 ms-4">Scans the latest news in real-time and feeds in the content of articles</div>

        <Form.Check
          className="mb-1"
          type="checkbox"
          id="newsEventCheck"
          label="News Event"
          name="newsevent"
          checked={contextInputs.newsevent}
          onChange={handleCheckboxChange}
        />
        <div className="text-secondary small mb-3 ms-4">Allows you to generate an email off of a specific news items</div>

        <Form.Check
          className="mb-1"
          type="checkbox"
          id="searchCheck"
          label="Custom Google Search"
          name="websearch"
          checked={contextInputs.websearch}
          onChange={handleCheckboxChange}
        />
        <div className="text-secondary small ms-4">Runs custom Google searches in real-time and feeds in relevant results</div>

      </Form.Group>

      {contextWebSearches.map((query, index) => (
        <div key={index} className="mt-3 d-flex align-items-center">
          <div className="search-query-label flex-shrink-0 text-nowrap me-3"><FontAwesomeIcon icon={faGoogle} className="me-1" /> Search {index+1}:</div>
          <Form.Control className="search-query me-3" type="text" value={query} readOnly />
          <Button variant="unstyled" className="me-3" onClick={() => handleEditQuery(index, query)}><FontAwesomeIcon className="small" icon={faPencil} /></Button>
          <Button variant="unstyled" onClick={() => handleDeleteQuery(index)}><FontAwesomeIcon className="small" icon={faTrashCan} /></Button>
        </div>
      ))}

      { (contextInputs.websearch && contextWebSearches.length < 5) && (
        <Button className="mt-3"  variant="dark" onClick={() => handleShowModal()}><span className="small"><FontAwesomeIcon icon={faPlus} className="me-1" /> Add query</span></Button>
      )}
      { (contextInputs.websearch && contextWebSearches.length >= 5) &&(
        <div className="text-secondary small mt-3">You can enter a maximum of 5 Google search queries as context for the email builder.</div>
      )}

      <Modal className="d-flex align-items-center" size="lg" show={showModal} onHide={() => {setShowModal(false); setInputValue("");}}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Add a Google search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Our AI will run a Google search in real-time, read the contents of the underlying search results and feed this into the context of the AI so that the generated email can pull from this information.</p>
          <p>You can use the following attributes in your Google search query (click to insert):</p>
          <div className="d-flex justify-content-between align-items-center mb-1">
              <Button variant="outline-dark" onClick={() => setInputValue(inputValue + "{COMPANY: name}")} className="me-2 mb-3 lh-1">Company Name</Button>
              <Button variant="outline-dark" onClick={() => setInputValue(inputValue + "{COMPANY: website_url}")} className="me-2 mb-3 lh-1">Company Website</Button>
              <Button variant="outline-dark" onClick={() => setInputValue(inputValue + "{COMPANY: primary_contact_first_name}")} className="me-2 mb-3 lh-1">Contact First Name</Button>
              <Button variant="outline-dark" onClick={() => setInputValue(inputValue + "{COMPANY: primary_contact_last_name}")} className="me-2 mb-3 lh-1">Contact Last Name</Button>
              <Button variant="outline-dark" onClick={() => setInputValue(inputValue + "{COMPANY: primary_contact_title}")} className="me-2 mb-3 lh-1">Contact Job Title</Button>
          </div>
          <Form.Control
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter Google query..."
            spellCheck="false"
            className="mb-3"
          />
          <Button variant="dark" onClick={handleAddQuery}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EmailTemplateContext;